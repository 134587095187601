import * as HRAutocomplete from './search-autocomplete'
import * as sliderToggle from './slider-toggle'
import {pw_activate, locksItineraries, pwAssess, getInCheckout, setInCheckout, accessData} from './paywall'
import * as destinationDining from './dest-dinning'
import {applyFilters} from './querystring-filters'
import * as navSearch from './nav-search'

const initialize = () => {
    /*
     * Initialize all UX js
     */
    HRAutocomplete.initialize();
    sliderToggle.initialize();
    navSearch.initialize()

};

export {
    applyFilters,
    destinationDining,
    initialize,
    locksItineraries,
    navSearch,
    pw_activate,
    pwAssess,
    getInCheckout,
    setInCheckout,
    accessData,
}
