/*
    defines which content types get obfuscated and which content blocks get squished.
    'type' is the /<content type>/ part of the URL path.
    'truncClass' is used to build the jquery selector used to truncate the content.
     */
const pwContent = [
    {type: 'hotels', truncClass: '.container_article-peek .container_article-wrap-nopadding'},
    {type: 'articles', truncClass: '.container_article-peek .container_article-wrap-nopadding'},
    {type: 'itinerary', truncClass: '.container_article-wrap-nopadding'}
];

/*
activates the paywall content obfuscation using classes defined in hideaway.css
 */
const pw_activate = () => {
    const truncString = new RegExp('\/(' + pwContent.map(function (el) {
        return el.type
    }).join('|') + ')\/');

    const contentArr = window.location.href.match(truncString);

    if (contentArr != null) {

        const typeStr = contentArr[1];

        /*
        Apply blur and fade
        these css classes are defined in hideaway.css
         */
        $('.container_article-peek').addClass(`pw_${typeStr}_grad`);
        $('.grid_article_img').addClass(`pw_${typeStr}_blur`);
        $('.slider_wrap').addClass(`pw_${typeStr}_none`);
        $('.hide_paywall').addClass(`pw_${typeStr}_none`);

        const truncSelector = pwContent.map(function (el) {
            if (el.type == typeStr) {
                return el.truncClass;
            }
        }).join('');

        /*
        remove all the content block except N blocks from the top (topBlocks)
         */
        const topBlocks = 1;
        const truncBlocks = $(truncSelector).length - topBlocks;
        $(truncSelector).slice(-1 * truncBlocks).css('display', 'none');
    }

    // hide the hotel names all over the place

    /*
    The event is emitted from algolia instant search on the render call back
    */
    $(document).on('search:hotels', {}, function (event, arg1, arg2) {
        $('.hotel_title').html('Hotel Name Hidden').addClass('title_locked-dark');

        // This isn't actually part of the search restults.  It's in the on() because sometimes these
        // fire before the page is rendered...  IE11
        $('.hotel_title').html('Hotel Name Hidden').addClass('title_locked-dark');
        $('.hotel_title_light').html('Hotel Name Hidden').addClass('title_locked');

    });

    $('.hotel_title').html('Hotel Name Hidden').addClass('title_locked-dark');
    $('.hotel_title_light').html('Hotel Name Hidden').addClass('title_locked');

    /* 
    The maps have a click handler that looks for this variable.
    When it's true, map pin popups will display the content of the variable
    */
    window.document.mapPopUpContent = '<a href="/join">Join, to see hotel names</a>';

    /*
    This chages the browser history entry and stores the real hotel path in the stateObj for the history entry.
    The results are three fold.
    1. the visible URL is changed to /join without redirecting the page
    2. If the user navigates away and then clicks back, they'll see /join.  If they click back again, they'll see the hotel page and this code will run again.
    3. The stateObj contains the real path for utility, should we need it after they've registered and we want to clean up the history for them. the popstate event fires and the listener gets the stateObj
    */
    var HRstateObj = {
        hotelPath: window.location.pathname,
    };
    const pat = /hotels\/\w+/
    if (window.location.pathname.match(pat) != null) {
        // change the history entry
        // history.pushState(HRstateObj, "Hotel name hidden", "/join");
        // change the visible page title
        window.document.title = 'Hideaway Report'
    }
};

/**
 * called from where ever you want - Composer, etc  UX.pwAssess()
 */
let inPianoCheckout = false;
const pwAssess = () => {
    new Promise(resolve =>
        tp.api.callApi('/access/list', {}, response => resolve(pwReact(response)))
    );
}

/**
 * call back for pwAssess's access list
 * @param response

No active subscription or grant will return response.data empty array
A grant with no expiration will return an array element with no expiration attribute.
A subscription with expiraiton in the future will have expiration_date attribute which is also the next billing date. 
 ...basically, if response.data.length > 0 then the user has access.
 
 */

var accessData = {};
const pwReact = (response) => {
    
    accessData = response.data;  // for console debugging or use elsewhere accessible as UX.accessData
    
    if (response.data) {
        if (response.data.length > 0) {
            // send the logged-out home page to the logged in home page
            if (window.location.pathname.match(/^\/$/) ||
                window.location.pathname == '/account/reset-password/') {
                location.href = '/home/';
                
            // send the login page to the /account home and let Piano 
            } else if (window.location.pathname.match(/^\/account\/login/)) {
                location.href = '/account/';
                
                
                
            // reload all other pages to have Piano redress the page with an Experience    
            } else {
                location.reload();
            }
        } else {
            // user has no access
            // If the user does not have access and is not currently in checkout, 
            // reload the page and let Piano redress the page with an experience
            if (UX.getInCheckout() !== true) {
                location.reload();
            }
        }
    }
}

const getInCheckout = () => {
    return inPianoCheckout;
}

const setInCheckout = (state) => {
    inPianoCheckout = state
}

const locksItineraries = () => {
    $('[data-itinerary-title-light]').addClass('title_locked-dark')
}

export {
    pw_activate,
    locksItineraries,
    pwAssess,
    setInCheckout,
    getInCheckout,
    accessData,
}
