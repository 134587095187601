/*
 * UX animation handling for toggle button slider/switch
 */

const toggleWrapperSelector = '.togglebutton';
const toggleButtonSelector = '.buttontoggle';
const toggleButtonClass = 'right';

const eventName = 'hr:toggle';

/*
 * toggle handler for buttontoggle class
 */
function sliderToggleClick(e) {
    let btn = $(this).find(toggleButtonSelector);
    btn.toggleClass(toggleButtonClass);
    btn.trigger(eventName, {id: this.id, state: btn.hasClass(toggleButtonClass)});
}

const initialize = () => {
    $(toggleWrapperSelector).click(sliderToggleClick);
};


export {
    initialize,
}
