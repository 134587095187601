/**
 * HR implimentation of Algolia autocomplete widget
 *
 * tutorial: https://www.algolia.com/doc/guides/building-search-ui/resources/ui-and-ux-patterns/in-depth/autocomplete/tutorials/how-to-display-results-from-multiple-indices-with-autocomplete-js/#html
 * dev function reference: https://github.com/algolia/autocomplete.js/blob/master/README.md#standalone
 * [0] Implicit Submission: https://tjvantoll.com/2013/01/01/enter-should-submit-forms-stop-messing-with-that/#no-submit-buttons
 *
 */
/* Debug mode keeps the aa dropdown from disappearing on focusout.
 * This is to allow use of dev tools to style/monitor things. Otherwise the toggle/autocomplete behavior means
 * that the relevant divs get hidden/emptied on focusout, which can complicate dev work.
 */
let debug = false;

/* Settings */
const algoliaAppId = "H63UBL6L71";
const algoliaPubKey = "fd9d49a6ff6e5106caea3b68d0f61acb";
const destinationsIndex = "destinations-autocomplete";
const hotelsIndex = "hotels-autocomplete";

/* Selectors */
const homeSearchInput = ['#aa-search-input', '#aa-search-topnav', '#aa-search-mobile'];
const dropDownContainer = '.search_dropdownlist';
const defaultDropDown = '#default-dropdown, #default-dropdown-topnav';
const aaDropDown = '#aa-dropdown, #aa-dropdown-topnav, #aa-dropdown-mobile';

/*
used to show/hide the default search drop down on the home pages.  better to use this over toggle()
 */
function toggleDefault(el) {
    if ($(el).val().length > 0) {
        $(defaultDropDown).hide();
        $(aaDropDown).slideDown(200);
    } else {
        $(aaDropDown).hide();
        $(defaultDropDown).slideDown(200);
    }
}

/*
 Hide / show based on user action on the home pages
  */
function attachDropdownToggleListener() {
    
    for(let el in homeSearchInput) {
        $(homeSearchInput[el])
            .keyup(() => {
                toggleDefault(homeSearchInput[el])
                $(dropDownContainer).show();
            })
            .focus(() => {
                toggleDefault(homeSearchInput[el]);
                $(dropDownContainer).slideDown();
            })
            .focusout(() => {
                if (!debug)
                    setTimeout(() => {
                        $(dropDownContainer).slideUp();
                    }, 250);
            });
    }
}

/*
Attaches Algolia's autocomplete widget to search input and does some related house keeping.

searchInput: dom element that's used to take search input.  MUST be an ID for this function
menuContainer: the parent container for the suggestions.  class || id || empty
 */
function attachAutoComplete(searchInput, menuContainer) {

    const client = algoliasearch(algoliaAppId, algoliaPubKey);
    const destinations = client.initIndex(destinationsIndex);
    const hotels = client.initIndex(hotelsIndex);

    const generateFooterQuery = () => {
        return `/search/?${$.param({query:$(searchInput).val()})}`
    };

    const generateFooter = () => {
        return `<div class="aa-suggestion aa-dropdown-footer" role="option" >
            <a href="${generateFooterQuery()}">
                <i class="fas fa-ellipsis-h pr10 pr0-m"></i>
                <span>All Results</span>
            </a>
        </div>`
    };

    let search = autocomplete(searchInput, {
        debug: true,  //makes the suggestions box stick around when set to true
        hint: false,  // Injects a second input into the form, which can break implicit submission[0]
        dropdownMenuContainer: menuContainer,
        templates: {
            footer: generateFooter()
        }
    }, [
        /**
         * Templating of the Destinations results
         */
        {
            source: autocomplete.sources.hits(destinations, {hitsPerPage: 5}),
            name: 'destinations',
            displayKey: 'name',
            templates: {
                empty: '<div class="aa-empty">No <i>destinations</i> found',
                suggestion: function (suggestion) {
                    return `<a href="${suggestion.main_object_url}"><i class="fal fa-map-marker-alt pr10 pr10-m"></i> <span class="suggestion">${suggestion.title}</span></a>`;
                }
            }
        },
        /**
         * Templating of the Hotel results - Hid them in case we need function in the future
         */
        // {
        //     source: autocomplete.sources.hits(hotels, {hitsPerPage: 3}),
        //     name: 'hotels',
        //     displayKey: 'name',
        //     templates: {
        //         empty: '<div class="aa-empty">No <i>hotels</i> found',
        //         suggestion: function (suggestion) {
        //             return `<a href="${suggestion.main_object_url}"><i class="fas fa-hotel"><span>${suggestion.title}</span></i></a>`;
        //         },
        //     }
        // }
    ])
        .on('autocomplete:shown', function (event, suggestion, dataset, context) {
            $(menuContainer+' .aa-dropdown-footer a').attr('href', generateFooterQuery());
        })
        .on('autocomplete:selected', function (event, suggestion, dataset, context) {
            if (dataset === undefined && suggestion === undefined) {
                $(dropDownContainer).hide();
                window.location = $('.aa-dropdown-footer.aa-cursor a').attr('href');
            }

            $(dropDownContainer).hide();
            window.location = suggestion.main_object_url;
        });
}

function initialize() {
    /* attach dropdown toggler */
    attachDropdownToggleListener();

    /* attach autocomplete to the home page searches */
    attachAutoComplete('#aa-search-input', '#aa-dropdown');
    $('.search_dropdownlist').hide();

    /* attach autocomplete to the mobile nav search */
    attachAutoComplete('#aa-search-mobile', '#aa-dropdown-mobile');

    /* attach autocomplete to desktop nav search */
    attachAutoComplete('#aa-search-topnav', '#aa-dropdown-topnav');

    /* dropdown gets populated on attach, and can be visible. Hide here */
    $(aaDropDown).hide();
    /* Remove inline styles that conflict with our styles */
    $('.aa-dropdown-menu[style]').removeAttr('style');
}

export {
    initialize
}
