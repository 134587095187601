
const diningList = '#restaurantList';
const diningItemWrapper = '.container_grid-item-dining';
const diningItemBody = '.container_dining-body';

const initialize = (options) => {
    let toggleItem = (e) => {
        $(e.currentTarget).find(diningItemBody).toggle(options);
    };

    let bind = () => {
        // Since we may need to re-bind, turn off click handlers first. Hopefully this doesn't come back to
        // bite us later.
        $(diningItemWrapper).off();
        $(diningItemWrapper).click(toggleItem);
    };

    /*
     * Setup a MutationObserver to monitor for new elements in the wrapper div around all dining items. If
     * new elements appear, re-bind the click handler.
     */
    let setupObserver = () => {
        let el = $(diningList)[0];
        let observer = new MutationObserver(() =>{
            bind();
        });

        observer.observe(el, {childList: true});
    };

    bind();
    setupObserver();
};

export {
    initialize,
}
