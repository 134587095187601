
const searchTriggerSelector = '.search_button-topnav-white, .search_button-topnav-dark';
const searchNavSelector = '.search_results-topnav';
const searchNavInputSelector = '#aa-search-topnav';
const searchCloseSelector = '.search_close-button-dark';

let animationDuration = 500; // 500ms comes from webflow.js

function openSearch() {
    $(searchNavSelector).slideDown(animationDuration);
    $(searchNavInputSelector).focus();
}

function closeSearch() {
    $(searchNavSelector).slideUp(animationDuration);
}

const initialize = () => {
    $(searchTriggerSelector).click(openSearch);
    $(searchCloseSelector).click(closeSearch);
};

export {
    initialize,
    openSearch,
    closeSearch,
}