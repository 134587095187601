/*
applies filters to the hotels page
 */
const applyFilters = () => {

    var qs = ah.util.parseQueryString();
    
    if ('hotelFilters' in qs) {
        var hotelFilters = qs.hotelFilters.split(',');
        for (var i = 0; i < hotelFilters.length; i++) {
            $(`#${hotelFilters[i]}`).click();
        }
    }
}

export {
    applyFilters
}
